import { useState, useEffect } from "react";

import { Flex, Typography, Skeleton, useBreakpointValue } from "@nestoca/ui";
import { useGetAccount } from "@shared/api/hooks/account";
import { DatadogMask } from "@shared/datadog";
import { getApplicantName } from "@shared/utils";
import clsx from "clsx";
import { useTranslation, Trans } from "next-i18next";

import styles from "./home-page.module.scss";

export const HomeHeader = ({ className }: { className?: string }) => {
  const [isClient, setIsClient] = useState(false);
  const { t } = useTranslation("common");
  const isMobile = useBreakpointValue({ default: true, md: false });
  const { data: account, isPending: isLoadingAccount } = useGetAccount();

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) return null;

  const applicantName = getApplicantName(account, t("unknownName"));

  return (
    <Flex className={clsx(styles["home-header"], className)} direction="column">
      {isMobile && (
        <Typography size={3} weight={7}>
          {t("portfolio")}
        </Typography>
      )}
      {isLoadingAccount && (
        <Skeleton containerClassName={styles.home__applicant} />
      )}
      <Typography weight={7} className={styles["home-header__text"]}>
        <Trans
          t={t}
          i18nKey={isMobile ? "account.header" : "account.headerLong"}
          values={{
            name: applicantName,
          }}
          components={[
            <DatadogMask
              key={0}
              className={styles["home-name"]}
              data-dd-aaction-name="applicant name"
            >
              {applicantName}
            </DatadogMask>,
          ]}
        />
      </Typography>
    </Flex>
  );
};
